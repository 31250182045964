import { useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormHelperText,
    Typography,
    Stack,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import externalUserApi from 'src/api/externalUserApi'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { TextInput } from 'src/components/TextInput'
import { CONSTANTS } from 'src/config/constants'
import { tableBodyText, tableHeaderText } from 'src/theme'
import ScheduleConfirmFooter from 'src/components/Common/ScheduleConfirmFooter'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import { NewDyskeLogo } from 'src/components/Icons'
import { LoadingButton } from '@mui/lab'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { getAdjustmentItemTime, groupByDate } from '../../helpers/formatDate'
import scheduleApi from 'src/api/scheduleApi'
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'

const ScheduleSingleExternalConfirmFluid = () => {
    const navigate = useNavigate()

    const { hash } = useParams()

    const [subject, setSubject] = useState<string>('')
    const [adjustments, setAdjustments] = useState<any>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isExpired, setIsExpired] = useState<boolean>(false)
    const [scheduleData, setScheduleData] = useState<any>(null)
    const { showSnackBar } = useSnackBar()
    const { height, width } = useWindowDimensions();
    const [isInvalidSchedule, setIsInvalidSchedule] = useState<boolean>(false)
    const tableRef = useRef<HTMLTableElement>(null);
    const [selectedAdjustment, setSelectedAdjustment] = useState<string | null>(null)
    const [progress, setProgress] = useState<number>(0)
    const [candidateError, setCandidateError] = useState<string | null>(null)
    const [scheduleDatetime, setScheduleDatetime] = useState<string | null>(null)

    const schema = yup.object({
        name: yup.string().required('必ず入力してください').max(100, '100文字以内で入力してください'),
        comment: yup.string().max(1000, '1000文字以内で入力してください'),
        email: yup
        .string()
        .email('正しく入力してください')
        .max(256, '256文字以内で入力してください')
        .required('必ず入力してください'),
        phone_number: yup.string().max(13, '13文字以内で入力してください')
        .matches(/^(0([1-9]{1}-?[1-9]\d{3}|[1-9]{2}-?\d{3}|[1-9]{2}\d{1}-?\d{2}|[1-9]{2}\d{2}-?\d{1})-?\d{4}|0[789]0-?\d{4}-?\d{4}|050-?\d{4}-?\d{4})$/, '正しく入力してください'),
        department_name: yup.string().max(100, '100文字以内で入力してください'),
        company_name: yup.string().max(100, '100文字以内で入力してください')
        .required('必ず入力してください'),
    })

    const {
        control,
        handleSubmit,
        getValues,
        setError,
        setValue,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            comment: '',
            email: '',
            phone_number: '',
            department_name: '',
            company_name: '',
        },
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (!hash) {
            setIsInvalidSchedule(true)
            return
        }
        validateHash(hash)
    }, [hash])

    const validateHash = async (hash: string) => {
        externalUserApi
            .validateHashSingleExternal(hash)
            .then((res: any) => {
                if (res?.success === false) {
                    setProgress(100)
                    setProgress(-1)
                    setIsInvalidSchedule(true)
                    return;
                }
                const data = res.data
                if (
                    data.schedule.status !== 'ADJUSTING'
                ) {
                    setIsExpired(true);
                    return;
                }

                setSubject(data.schedule.subject)
                setScheduleData(data.schedule)

                const adjustment_period = data.schedule.adjustment_period
                const today = dayjs().startOf('day')
                const endDate = today.add(adjustment_period, 'week').endOf('day')
                
                const oldTimeRanges = data.schedule.search_condition.timeRanges[0]
                const oldStartTime = dayjs(oldTimeRanges.start)
                const oldEndTime = dayjs(oldTimeRanges.end)
                const searchCondition = {
                    ...data.schedule.search_condition,
                    timeRanges: [
                        {
                            start: today.hour(oldStartTime.hour())
                                      .minute(oldStartTime.minute())
                                      .second(oldStartTime.second())
                                      .toISOString(),
                            end: endDate.hour(oldEndTime.hour())
                                    .minute(oldEndTime.minute())
                                    .second(oldEndTime.second())
                                    .toISOString()
                        }
                    ]
                }

                const candidateTimes = scheduleApi
                    .getCandidateTimeV2ForSingleExternal(data.schedule.id, {page: 1, take: 99999} , searchCondition)
                    .then((res: any) => {
                        const adjustment = groupByDate(res.data.candidate_time, data.schedule) 
                        setAdjustments(adjustment)

                        const BATCH_SIZE = 10;
                        const total = res.data.candidate_time.length
                        let i = 0
                        const timer = setInterval(() => {
                            if (i > total) {
                                clearInterval(timer)
                                setProgress(-1)
                                return
                            }
                            if (i % BATCH_SIZE === 0 || i === total) {
                                setProgress((i / total) * 100);
                            }
                            i++
                        }, 10)

                        return () => {
                            if (timer) {
                                setProgress(-1)
                                clearInterval(timer)
                            }
                        };
                    })

                reset({
                    name: data?.user?.name ?? '',
                    comment: data?.participant?.comment ?? '',
                })
            })
            .catch((err: any) => {
                console.log(err)
                setProgress(-1)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onError = (errors: any) => {
        const firstError = '入力エラーを修正してください'
        if (firstError) {
            showSnackBar(true, firstError as string, 'error')
        }
        if(selectedAdjustment === null) {
            setCandidateError('必ず選択してください')
        } else {
            setCandidateError(null)
        }
    }

    const confirm = (data: any) => {
        if (isSubmitting) {
            return
        }
        setIsSubmitting(true)

        if (selectedAdjustment === null) {
            setIsSubmitting(false)
            setCandidateError('必ず選択してください')
            return;
        }

        if (hash !== '' && hash !== undefined) {
            const input = {
                adjustment: selectedAdjustment,
                name: data.name,
                comment: data.comment,
                email: data.email,
                phone_number: data.phone_number,
                department_name: data.department_name,
                company_name: data.company_name,
            }
            externalUserApi
            .sharedUrlSingleExternalFluidConfirm(hash, input)
            .then((res: any) => {
                setIsSubmitting(false)
                if (res?.success === false) {
                    if(res.data && res.data?.status !== 'ADJUSTING') {
                        const message = res.data?.is_cancelled ? 'スケジュールがキャンセルされたため、回答を変更することはできません。' : '予定がすでに確定しているため、回答の変更はできません。';
                        showSnackBar(true, message, 'error')
                        return;
                    }
                    showSnackBar(true, `メールの送信に失敗しました`, 'error')
                    return;
                }
                setIsSuccess(true)
            })
            
        } else {
            setIsSubmitting(false)
            showSnackBar(true, `エラーが発生しました。管理者にお問合せください。`, 'error')
        }
    }

    const setDimensionLogo = () => {
        if (width > 600) {
            return 140;
        }
        
        return 120;
    }

    //Change padding top from 60px to 0px
    const thanksPageComponent = () => {
        return (
            <Box sx={{padding: { xs: '0 15px 15px 15px', sm: '0px 40px 60px 40px' , display: 'flex', justifyContent: 'center', alignItems: 'center'}}}>
                <Stack spacing={1} direction={'column'} sx={{maxWidth: '680px'}}>
                    <Typography sx={styles.thanksText}>この度は、スケジュールを調整いただき、誠にありがとうございます。</Typography>
                    <Typography sx={styles.thanksText}>下記で日時が確定しました。</Typography>
                    <br />

                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <Typography sx={[styles.thanksText, {textAlign: 'right', minWidth: scheduleData?.note ? { xs: '145px', sm: '160px' } : 'auto'}]}>予定日時：</Typography>
                            {scheduleData?.note && (
                                <Typography sx={[styles.thanksText, {textAlign: 'right', minWidth: { xs: '145px', sm: '160px' }}]}>ミーティングURL：</Typography>
                            )}
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <Typography sx={styles.thanksText}>{scheduleDatetime}</Typography>
                            {scheduleData?.note && (
                                <Typography sx={[styles.thanksText, {whiteSpace: 'pre-wrap', wordBreak: 'break-word'}]}>
                                    <span dangerouslySetInnerHTML={{ __html: scheduleData?.note?.replace(/\n/g, '<br/>') }} />
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <br />
                    <Typography sx={styles.thanksText}>当日はよろしくお願いいたします。<br></br>　（上記内容はメールでも送付いたします）</Typography>
                </Stack>
            </Box>
        )
    }

    const handleAdjustmentItemSelect = (dateTime: string) => {
        setSelectedAdjustment(dateTime)
        const startTime = dayjs(dateTime);
        const dateFormat = `YYYY/M/D(${CONSTANTS.DAY_OF_WEEK[startTime.day()]})`;
        setScheduleDatetime(startTime.format(dateFormat) + ' ' + startTime.format('HH:mm') + '〜(' + scheduleData.search_condition.timeInterval + '分)');
        setCandidateError(null)
    }

    return (
        <>
        {
            !isLoading && progress === -1 ? (
                <Box sx={{position: 'relative', minHeight: '100vh'}}>
                    <Box>
                        <Typography sx={styles.titleText}><NewDyskeLogo width={setDimensionLogo()}/></Typography>
                        {
                        (() => {
                            if (isInvalidSchedule) {
                                return (
                                    <Box sx={styles.thanksContainer}>
                                        <Stack spacing={1} direction={'column'}>
                                            <Typography sx={styles.thanksText}>予定が無効です。</Typography>
                                        </Stack>
                                    </Box>
                                )
                            } else if (isExpired) {
                                return (
                                    <Box sx={styles.thanksContainer}>
                                        <Stack spacing={1} direction={'column'}>
                                            <Typography sx={styles.thanksText}>回答期限が切れました。</Typography>
                                        </Stack>
                                    </Box>
                                )
                            } else if (isSuccess) {
                                return thanksPageComponent()
                            } else {
                                return (
                                    <Box component="form" noValidate sx={styles.form}>
                                        <Grid container spacing={3} sx={styles.container}>
                                            <Grid xs={12} sx={[styles.invitees, {paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: '16px',
                                                            sm: '18px',
                                                        },
                                                        letterSpacing: '0rem',
                                                        lineHeight: 1.8,
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                        下記より、予定「
                                                        <b>{subject}</b>
                                                        」への参加が可能な日時をご回答頂けますと幸いです。
                                                </Typography>
                                            </Grid>
                                           
                                            <Grid xs={12} sm={6} md={4} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Stack spacing={1} direction={'column'}>
                                                    <Typography sx={[styles.labelConfirm]}>会社名  <Typography component="span" sx={styles.requiredText}>必須</Typography></Typography>
                                                    <Controller
                                                        name="company_name"
                                                        control={control}
                                                        defaultValue={getValues('company_name')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="例）グローバルプロデュース"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={styles.textInputCustom}
                                                                />
                                                                {error && <FormHelperText sx={styles.errorText} error>{error.message}</FormHelperText>}
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid xs={12} sm={6} md={4} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Stack spacing={1} direction={'column'}>
                                                    <Typography sx={[styles.labelConfirm]}>部署名</Typography>
                                                    <Controller
                                                        name="department_name"
                                                        control={control}
                                                        defaultValue={getValues('department_name')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="例）イベント事業部"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={styles.textInputCustom}
                                                                />
                                                                {error && <FormHelperText sx={styles.errorText} error>{error.message}</FormHelperText>}
                                                            </>
                                                            )}
                                                        />
                                                </Stack>
                                            </Grid>
                                            
                                            <Grid xs={12} sm={6} md={4} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Stack spacing={1} direction={'column'}>
                                                    <Typography sx={[styles.labelConfirm]}>氏名 <Typography component="span" sx={styles.requiredText}>必須</Typography></Typography>
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        defaultValue={getValues('name')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="例）宮永 麻代"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={styles.textInputCustom}
                                                                />
                                                                {error && <FormHelperText sx={styles.errorText} error>{error.message}</FormHelperText>}
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid xs={12} sm={6} md={6} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Stack spacing={1} direction={'column'}>
                                                    <Typography sx={[styles.labelConfirm]}>メールアドレス<Typography component="span" sx={styles.requiredText}>必須</Typography></Typography>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        defaultValue={getValues('email')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="例）miyanaga@global-produce.jp"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={styles.textInputCustom}
                                                                />
                                                                {error && <FormHelperText sx={styles.errorText} error>{error.message}</FormHelperText>}
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid xs={12} sm={6} md={6} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Stack spacing={1} direction={'column'}>
                                                    <Typography sx={[styles.labelConfirm]}>電話番号</Typography>
                                                    <Controller
                                                        name="phone_number"
                                                        control={control}
                                                        defaultValue={getValues('phone_number')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="例）03-5738-2117"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={{
                                                                        '& ::placeholder': {
                                                                            fontSize: {
                                                                                xs: '16px',
                                                                                sm: '18px',
                                                                            },
                                                                            fontWeight: '400',
                                                                        },
                                                                        input: {
                                                                            color: '#000000',
                                                                            fontSize: {
                                                                                xs: '16px',
                                                                                sm: '18px',
                                                                            },
                                                                            fontWeight: '400',
                                                                        },
                                                                    }}
                                                                />
                                                                {error && <FormHelperText sx={styles.errorText} error>{error.message}</FormHelperText>}
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid
                                                xs={12}
                                                sx={[
                                                    styles.tableGrid,
                                                    {
                                                        paddingTop: {xs: '0px', sm: '12px' },
                                                    },
                                                    adjustments.length <= 1 && {
                                                        pb: {
                                                            xs: 0,
                                                            sm: '10px'
                                                        },
                                                    }
                                                ]}
                                            >
                                                <TableContainer
                                                    sx={[
                                                        styles.tableContainer,
                                                        {
                                                            paddingLeft: {
                                                                xs: '12px',
                                                            },
                                                            paddingRight: {
                                                                xs: '12px',
                                                            }
                                                        }
                                                    ]}
                                                    className='js-scrollable scroll-hint'
                                                >
                                                    {adjustments && adjustments.length > 0 && (
                                                        <>
                                                        <Table sx={styles.table} ref={tableRef}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Typography sx={[styles.labelConfirm]}>
                                                                            候補日時 <Typography component="span" sx={styles.requiredText}>必須</Typography>
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {progress === -1 && adjustments.map((row: any, index: number) => {
                                                                    return row.start.length > 0 && (
                                                                        <TableRow key={index} hover>
                                                                            <TableCell>
                                                                                <Typography sx={styles.tableBodyTextCustom}>
                                                                                {(() => {
                                                                                        const startTime = dayjs(row?.start[0]?.dateTime);
                                                                                        const dateFormat = `YYYY/M/D(${CONSTANTS.DAY_OF_WEEK[startTime.day()]})`;
                                                                                        
                                                                                        if (row?.start[0]?.duration === 1439) {
                                                                                            return startTime.format(dateFormat);
                                                                                        }

                                                                                        return startTime.format(dateFormat);
                                                                                    })()}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                            <Box sx={[
                                                                                        styles.tableBodyTextCustom,
                                                                                        { display: 'flex', flexWrap: 'wrap', gap: '10px', width: '100%' }
                                                                                    ]}>
                                                                                    {(() => {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    (row.start).map((item: any, index: number) => (
                                                                                                        <Button onClick={() => handleAdjustmentItemSelect(item.dateTime)} key={index} variant='text' sx={[
                                                                                                            styles.adjustmentItemButton,
                                                                                                            selectedAdjustment === item.dateTime && styles.adjustmentItemButtonSelected,
                                                                                                            { flexShrink: 0 }
                                                                                                        ]}>
                                                                                                            {item.duration === 1439 ? '終日' : getAdjustmentItemTime(item.dateTime, scheduleData?.search_condition.timeInterval)}
                                                                                                        </Button>
                                                                                                    ))
                                                                                                }
                                                                                            </>
                                                                                        );
                                                                                    })()}
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                        </>
                                                    )}
                                                </TableContainer>
                                                {candidateError && <FormHelperText sx={[styles.errorText, {mt: '10px', ml: '10px'}]} error>{candidateError}</FormHelperText>}
                                            </Grid>

                                            <Grid xs={12} sx={[styles.invitees, adjustments.length <= 1 && {paddingTop: 0}]}>
                                                <Stack sx={{width: '100%'}} direction={'column'} spacing={1}>
                                                    <Typography sx={[styles.labelConfirm]}>備考</Typography>
                                                    <Controller
                                                        name="comment"
                                                        control={control}
                                                        defaultValue={getValues('comment')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="※具体的な相談内容がある場合は、できるだけ詳細にご記載ください。"
                                                                    multiline
                                                                    rows={4}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={styles.textInputCustom}
                                                                />
                                                                {error && <FormHelperText sx={styles.errorText} error>{error.message}</FormHelperText>}
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid xs={12}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                                    <LoadingButton loading={isSubmitting}  disabled={isSubmitting} variant="outlined" onClick={handleSubmit(confirm, onError)} sx={isSubmitting ? styles.buttonFooterModalSubmit : styles.buttonFooterModal}>
                                                        回答する
                                                    </LoadingButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        })()
                    }
                </Box>
                <ScheduleConfirmFooter />
            </Box>
        ) : (
            <>
                {progress >= 0 && (
                    <Box sx={{
                        position: 'relative', 
                        minHeight: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{ width: '200px'}}>
                            <LinearProgressWithLabel value={progress} />
                        </Box>
                    </Box>
                )}
            </>
        )
    }
</>
)
}

export default ScheduleSingleExternalConfirmFluid

const styles = {
    titleText: {
        fontSize: '32px',
        color: '#000',
        fontWeight: 'bold',
        lineHeight: 1,
        letterSpacing: '0.1rem',
        textAlign: 'center',
        marginTop: '80px',
        marginBottom: '80px',
        '@media (max-width: 600px)': {
            marginTop: '20px',
            marginBottom: '20px',
        },
    },
    descriptionText: {
        color: '#000',
        fontSize: '15px',
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        textAlign: 'center',
    },

    thanksContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: { xs: '0px 20px 20px 20px', sm: '0px 40px 40px 40px' },
    },
    thanksText: {
        fontWeight: '400',
        fontSize: {
            xs: '16px',
            sm: '18px',
        },
        lineHeight: 1.8,
        letterSpacing: '0rem',
        textAlign: 'left',
    },
    form: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        position: 'relative',
        padding: { xs: '0px', sm: '0px 40px 40px 40px' },
    },
    container: {
        maxWidth: '960px',
        margin: 0,
        borderRadius: '20px',
        background: 'rgba(255, 255, 255, 0.9)',
        mb: '100px',
    },
    invitees: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    footerModal: { display: 'flex', flexDirection: 'row' },
    buttonFooterModal: {
        borderRadius: '15px',
        color: '#FFFFFF',
        fontWeight: '500',
        bgcolor: '#0075FF',
        fontSize: '16px',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        p: '12.5px 39px',
        ':hover': {
            bgcolor: '#0075FF',
        },
    },

    adjustmentItemButton: {
        borderRadius: '8px',
        color: '#000000',
        fontWeight: '500', 
        fontSize: '14px',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        p: '10px 10px',
        ':hover': {
          bgcolor: '#0075FF',
          color: '#FFFFFF',
          border: '1px solid #FFFFFF',
        },
        border: '1px solid #000000',
        height: '36px',
        width: {
            xs: 'calc(50% - 5px)',
            '@media (min-width: 450px)': {
                width: 'calc(33.33% - 7px)',
            },
            '@media (min-width: 690px)': {
                width: 'calc(25% - 8px)',
            },
            '@media (min-width: 800px)': {
                width: 'calc(20% - 8px)',
            },
            '@media (min-width: 900px)': {
                width: 'calc(16.666% - 8.5px)',
            }
        },
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    adjustmentItemButtonSelected: {
        bgcolor: '#0075FF',
        color: '#FFFFFF',
        border: '1px solid #FFFFFF',
    },

    buttonFooterModalSubmit: {
        borderRadius: '15px',
        color: '#FFFFFF',
        fontWeight: '500',
        bgcolor: 'rgba(0, 0, 0, 0.12)',
        fontSize: '16px',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        p: '12.5px 39px',
        ':hover': {
            bgcolor: 'rgba(0, 0, 0, 0.12)',
        },
    },
    inputName: {
        borderRadius: '10px',
        input: {
            color: 'black !important',
        },
    },
    noticeInputName: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#747E8B',
        letterSpacing: '0rem',
    },
    tableGrid: {
        overflowX: 'auto',
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        margin: 0 ,
    },
    tableContainer: {
        pt: {
            xs: 0,
            sm: "20px"
        },
        margin: {
            xs: 0,
        },
        boxSizing: 'border-box',
        '::-webkit-scrollbar' : {
            display: 'none'
        },
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        th: {
            ...tableHeaderText,
            padding: {
                xs: '8px',
                sm: '8px 16px'
            },
            borderBottom: '1px solid #cccccc',
        },
        'thead > tr': {
            borderBottom: '1px solid #cccccc',
        },
        'tbody > tr': {
            cursor: 'pointer',
        },
        tr: {
            borderBottom: '1px solid #cccccc',
            '@media (max-width: 600px)': {
                padding: '8px 0px',
            }
        },
        td: {
            ...tableBodyText,
            padding: {
                xs: '8px',
                sm: '8px 16px'
            },
            '@media (max-width: 600px)': {
                padding: '8px 0px',
            },
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #cccccc',
            '&:first-of-type': {
                width: '150px',
                minWidth: '150px',
                '@media (max-width: 600px)': {
                    width: '128px',
                    minWidth: '128px',
                }
            },
            '&:nth-of-type(2)': {
                width: 'auto',
            }
        },
        marginBottom: 0,
    },
    tableBodyTextCustom: {
        fontSize: {
            xs: '14px',
            sm: '16px',
        },
        fontWeight: '500',
        lineHeight: '1.4',
        letterSpacing: '0rem',
    },
    lightField: {
        width: '40px',
        height: '40px',
        position: 'relative',
        borderRadius: '12px',
    },
    labelConfirm: {
        fontSize: {
            xs: '14px',
            sm: '15px',
        },
        lineHeight: '1.5',
        fontWeight: '500',
        letterSpacing: '0rem',
        marginLeft: {
            xs: '-8px',
            sm: '-15px',
        }
    },
    textInputCustom: {
        '& ::placeholder': {
            fontSize: {
                xs: '16px',
                sm: '18px',
            },
            fontWeight: '400',
        },
        input: {
            color: '#000000',
            fontSize: {
                xs: '16px',
                sm: '18px',
            },
            fontWeight: '400',
        }
    },
    requiredText: {
        fontSize: '13px',
        color: '#ff0000',
        marginLeft: '10px',
        fontWeight: '700',
    },
    errorText: {
        color: '#ff0000',
        fontWeight: '700',
    }
}