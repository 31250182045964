const MultiExternal = (props: any) => (
    <svg width="19" height="19" id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.5 22">
        <defs>
            <style>
                {`
                    .cls-3{fill:#454545;}
                `}
            </style>
        </defs>
        <g id="_レイヤー_1-2">
            <path className="cls-3" d="M6.19,0c1.9,0,3.44,1.54,3.44,3.44s-1.54,3.44-3.44,3.44-3.44-1.54-3.44-3.44S4.29,0,6.19,0Zm15.81,0c1.9,0,3.44,1.54,3.44,3.44s-1.54,3.44-3.44,3.44-3.44-1.54-3.44-3.44,1.54-3.44,3.44-3.44ZM0,12.83c0-2.53,2.05-4.58,4.58-4.58h1.83c.68,0,1.33,.15,1.92,.42-.06,.31-.08,.63-.08,.96,0,1.64,.72,3.12,1.86,4.13H.92c-.5,0-.92-.41-.92-.92Zm17.42,.92h-.03c1.14-1.01,1.86-2.48,1.86-4.13,0-.33-.03-.64-.08-.96,.58-.27,1.23-.42,1.92-.42h1.83c2.53,0,4.58,2.05,4.58,4.58,0,.51-.41,.92-.92,.92h-9.17Zm-7.79-4.13c0-2.28,1.85-4.12,4.13-4.12s4.12,1.85,4.12,4.12-1.85,4.13-4.12,4.13-4.13-1.85-4.13-4.13Zm-4.12,11.23c0-3.16,2.57-5.73,5.73-5.73h5.04c3.17,0,5.73,2.57,5.73,5.73,0,.63-.51,1.15-1.15,1.15H6.64c-.63,0-1.15-.51-1.15-1.15h0Z"/>
        </g>
    </svg>
)
export default MultiExternal
