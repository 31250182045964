import { Box, Tab, Typography, Container, Button, Chip, Stack } from '@mui/material'
import FileIcon from 'src/components/Icons/FileIcon'
import CalendarIcon from 'src/components/Icons/CalendarIcon'
import ClockIcon from 'src/components/Icons/ClockIcon'
import UserGroupIcon from 'src/components/Icons/UserGroupIcon'
import { useState } from 'react'
import { useEffect } from 'react'
import scheduleApi from 'src/api/scheduleApi'
import { Schedule } from 'src/models/schedule'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { CONSTANTS } from 'src/config/constants'
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'
import adjustmentApi from 'src/api/adjustmentApi'
import ButtonCustom from 'src/components/Common/ButtonCustom'

const SingleExternal = () => {
    const { sid } = useParams()
    const navigate = useNavigate()
    const [scheduleTags, setScheduleTags] = useState<any>([])

    const [schedule, setSchedule] = useState<any>({})
    const [adjustmentTime, setAdjustmentTime] = useState<string>('')
    const [timeRange, setTimeRange] = useState<string>('')
    const [timeDuration, setTimeDuration] = useState<string>('')
    const [timeMargin, setTimeMargin] = useState<string>('')
    const [timeStart, setTimeStart] = useState<string>('')
    const [conditionOfInternalUser, setConditionOfInternalUser] = useState<string>('')
    const [participants, setParticipants] = useState<string>('')
    const [progress, setProgress] = useState(0);

    const replaceGaroonName = (user: any) => {
        const surName = user.sur_name || '';
        const givenName = user.given_name || '';
      
        return surName.length > 0 && givenName.length > 0
          ? `${surName} ${givenName}`
          : user.name;
    }

    useEffect(() => {
        const fetchSchedule = async () => {
            if (!sid) return
            try {
                const response = await scheduleApi.getById({ id: sid })
                if(!response.data || response.data.type !== CONSTANTS.TYPE_ADJUSTMENT.SINGLE_EXTERNAL) {
                    navigate('/top')
                }
                const schedule = response.data
                setSchedule(schedule)
                if(schedule.period_type === CONSTANTS.PERIOD_TYPE.UNFIXED) {
                    setAdjustmentTime(schedule.adjustment_period + '週間')
                } else {
                    const startDate = new Date(schedule.adjustment_start_date)
                    const endDate = new Date(schedule.adjustment_end_date)
                    const startDateStr = startDate.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
                    const endDateStr = endDate.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
                    setAdjustmentTime(startDateStr + '(月) ' + '- ' + endDateStr + '(月)')
                }
                
                if(schedule.search_condition) {
                    const timeRange = schedule.search_condition.timeRanges
                    const startTime = new Date(timeRange[0].start)
                    const endTime = new Date(timeRange[0].end)
                    
                    const timeRangeStr = startTime.toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit' }) + 
                        '〜' + endTime.toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit' })
                    setTimeRange(timeRangeStr)

                    setTimeDuration(schedule.search_condition.timeInterval + '分')
                    setTimeMargin(schedule.search_condition.marginTime + '分')
                    setTimeStart(schedule.search_condition.timeFrame + '分刻み')
                }

                setConditionOfInternalUser(schedule.condition_of_internal_user)

                const participants = schedule.participant.map((participant: any) => replaceGaroonName(participant.user)).join('、')
                setParticipants(participants)
            } catch (error) {
                console.error('Failed to fetch schedule:', error)
                navigate('/top')
            }
        }

        const getListScheduleTags = () => {
            adjustmentApi.getListScheduleTags().then((res: any) => {
                if (res.success) {
                    setScheduleTags(res.data);
                    console.log(res.data)
                }
                return res;
            })
            .catch((err) => {return []})
            .finally(() => {})
        }

        fetchSchedule()

        getListScheduleTags()

        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                return oldProgress + 10;
            });
        }, 100);
    }, [sid])

    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    return (
        <Container sx={{ p: { xs: '0', sm: '0 25px !important' } }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: '4px',
                }}
            >
                <Box sx={styles.container}>
                    {progress < 100 ? (
                        <Box sx={{
                            position: 'relative', 
                            display: 'flex',
                            height: { xs: '360px', sm: '257px' },
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box sx={{ width: '200px'}}>
                                <LinearProgressWithLabel value={progress} />
                            </Box>
                        </Box>
                    ) : (
                        <Stack spacing={3}>
                        {/* Title row */}
                            <Box sx={styles.rowContainer}>
                                <Box sx={styles.leftSection}>
                                    <FileIcon />
                                    <Typography variant="h6" sx={styles.leftText}>
                                        予定タイトル
                                    </Typography>
                                </Box>
                                <Box sx={styles.rightSection}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Button
                                            size="small"
                                            sx={{ 
                                                mr: '20px',
                                                bgcolor: scheduleTags.find((tag: any) => tag.name === schedule.event_menu)?.color,
                                                color: '#fff',
                                                padding: '0px 10px',
                                                height: '21px',
                                                minWidth: 'unset',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                borderRadius: '25px',
                                                '&:hover': {
                                                    bgcolor: scheduleTags.find((tag: any) => tag.name === schedule.event_menu)?.color,
                                                }
                                            }}
                                        >
                                            {schedule.event_menu}
                                        </Button>
                                        <Typography sx={styles.rightText}>{schedule.subject}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                            {/* Date row */}
                            <Box sx={{ ...styles.rowContainer, marginTop: '20px !important' }}>
                                <Box sx={styles.leftSection}>
                                    <CalendarIcon />
                                    <Typography sx={styles.leftText}>調整期間</Typography>
                                </Box>
                                <Box sx={styles.rightSection}>
                                    <Typography sx={styles.rightText}>{adjustmentTime}</Typography>
                                </Box>
                            </Box>

                            {/* Time row */}
                            <Box sx={{ ...styles.rowContainer, marginTop: '20px !important' }}>
                                <Box sx={styles.leftSection}>
                                    <ClockIcon />
                                    <Typography sx={styles.leftText}>調整時間</Typography>
                                </Box>
                                <Box sx={styles.rightSection}>
                                    {timeRange && (
                                        <Typography sx={styles.rightText}>
                                            {timeRange}
                                            <br />
                                            予定時間：{timeDuration}　前後時間：{timeMargin}　開始時間：{timeStart}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            {/* Participants row */}
                            <Box sx={{ ...styles.rowContainer, marginTop: '20px !important' }}>
                                <Box sx={styles.leftSection}>
                                    <UserGroupIcon />
                                    <Typography sx={styles.leftText}>参加者</Typography>
                                </Box>
                                <Box sx={styles.rightSection}>
                                    <Typography sx={styles.rightText}>
                                        {participants}
                                        {schedule.participant.length > 1 && (
                                            <>
                                                <br />
                                                ※参加条件：<span style={{ textTransform: 'uppercase' }}>{conditionOfInternalUser}</span>
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Back button */}
                            <ButtonCustom
                                sx={styles.backButton}
                                child={'戻る'}
                                onClick={() => navigate(-1)}
                            />
                        </Stack>
                    )}
                </Box>
                
            </Box>
        </Container>
    )
}

const styles = {
    container: {
        width: '100%',
        typography: 'body1',
        borderRadius: '20px',
        padding: { xs: '20px', sm: '30px', md: '40px 60px' },
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        alignSelf: 'center',
    },
    rowContainer: {
        display: 'flex',
        width: '100%',
        gap: 1,
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'flex-start',
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        minWidth: { xs: 'auto', sm: '140px' },
    },
    rightSection: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    leftText: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#000000',
    },
    rightText: {
        fontSize: '15px',
        fontWeight: 400,
        color: '#000000',
    },
    backButton: {
        width: '120px',
        height: '40px',
        borderRadius: '8px',
        border: '1px solid #000000',
        color: '#000000',
        backgroundColor: '#ffffff',
        fontSize: '14px',
        fontWeight: 700,
        marginLeft: { xs: '0', sm: '-10px !important', md: '-15px !important' },
        marginTop: { xs: '20px !important', sm: '40px !important' },
    }
}

export default SingleExternal
