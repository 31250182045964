const CalendarIcon = () => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3194_899)">
                <path d="M5.42857 0.84375C5.42857 0.376172 5.04643 0 4.57143 0C4.09643 0 3.71429 0.376172 3.71429 0.84375V2.25H2.28571C1.025 2.25 0 3.25898 0 4.5V5.0625V6.75V15.75C0 16.991 1.025 18 2.28571 18H13.7143C14.975 18 16 16.991 16 15.75V6.75V5.0625V4.5C16 3.25898 14.975 2.25 13.7143 2.25H12.2857V0.84375C12.2857 0.376172 11.9036 0 11.4286 0C10.9536 0 10.5714 0.376172 10.5714 0.84375V2.25H5.42857V0.84375ZM1.71429 6.75H14.2857V15.75C14.2857 16.0594 14.0286 16.3125 13.7143 16.3125H2.28571C1.97143 16.3125 1.71429 16.0594 1.71429 15.75V6.75ZM3.42857 9.84375C3.42857 10.3113 3.81071 10.6875 4.28571 10.6875H11.7143C12.1893 10.6875 12.5714 10.3113 12.5714 9.84375C12.5714 9.37617 12.1893 9 11.7143 9H4.28571C3.81071 9 3.42857 9.37617 3.42857 9.84375ZM4.28571 12.375C3.81071 12.375 3.42857 12.7512 3.42857 13.2188C3.42857 13.6863 3.81071 14.0625 4.28571 14.0625H8.28572C8.76071 14.0625 9.14286 13.6863 9.14286 13.2188C9.14286 12.7512 8.76071 12.375 8.28572 12.375H4.28571Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_3194_899">
                    <rect width="16" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CalendarIcon