export const checkRoute = (pathname: string) => {
    let screen_name = ''
    if (pathname === '/top') {
        screen_name = ''
    }
    if (pathname === '/schedule-list') {
        screen_name = '調整一覧'
    }
    if (
        pathname.includes('/adjustment/step1') ||
        pathname.includes('/adjustment/step2') ||
        pathname.includes('/adjustment/step3')
    ) {
        screen_name = '新規調整'
    }
    if (pathname.includes('/finalize')) {
        screen_name = '最終調整'
    }
    if (pathname.includes('/group/member-manage')) {
        screen_name = 'Myグループ作成'
    }
    if (pathname.includes('/group/list')) {
        screen_name = 'Myグループ一覧'
    }
    if (pathname.includes('/single-external')) {
        screen_name = '予定確認（社外1名）'
    }
    return screen_name
}
