const FileIcon = () => {
    return (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3194_909)">
                <path d="M0 2C0 0.896875 0.896875 0 2 0H7V4C7 4.55312 7.44688 5 8 5H12V14C12 15.1031 11.1031 16 10 16H2C0.896875 16 0 15.1031 0 14V2ZM12 4H8V0L12 4Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_3194_909">
                    <rect width="12" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default FileIcon