const SingleExternalBlack = (props: any) => (
    <svg width="15" height="15" id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20.57">
        <defs>
            <style>
                {`
                    .cls-2{fill:#000000;}
                `}
            </style>
        </defs>
        <g id="_レイヤー_1-2">
            <path className="cls-2" d="M9,10.29c2.84,0,5.14-2.3,5.14-5.14S11.84,0,9,0,3.86,2.3,3.86,5.14s2.3,5.14,5.14,5.14Zm-1.84,1.93c-3.96,0-7.16,3.21-7.16,7.16,0,.66,.53,1.19,1.19,1.19h15.61c.66,0,1.19-.53,1.19-1.19,0-3.96-3.21-7.16-7.16-7.16h-3.67Z"/>
        </g>
    </svg>
)
export default SingleExternalBlack
